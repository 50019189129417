import React from "react";

const ContentSummariserSummary = () =>{
    return (
        <div className="template">
                <img src={require('../img/summariser.png')} alt="logo" className="template-img" />
                <h5>Content Summariser</h5>
                <p>Summarise articles, blogs, documents, news reports, etc</p>
        </div>
    )
}

export default ContentSummariserSummary