import React, { useState, useEffect } from "react";
import Sidebar from "../../layout/Sidebar";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../../../config/fbConfig";
import { getAuth } from "firebase/auth";
import { getFirestore, getDoc, doc, updateDoc } from "firebase/firestore";
import axios from "axios";
import { motion } from "framer-motion";
import { set } from "lodash";
import FadeLoader from "react-spinners/FadeLoader";

const transitionVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
  },
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
export default function Video() {
  // UseStates
  const [descriptionCount, setDescriptionCount] = useState(0);
  const [descriptionCountTwo, setDescriptionCountTwo] = useState(0);
  const [descriptionCountThree, setDescriptionCountThree] = useState(0);
  const [descriptionCountFour, setDescriptionCountFour] = useState(0);
  const [heightCount, setHeightCount] = useState(80);
  const [promptOne, setPromptOne] = useState("");
  const [promptTwo, setPromptTwo] = useState("");
  const [promptThree, setPromptThree] = useState("");
  const [promptFour, setPromptFour] = useState("");
  const [responses, setResponse] = useState([]);
  const [tokens, setTokens] = useState(null);
  const [nameCount, setNameCount] = useState(0);
  const [success, setSuccess] = useState("false");
  const [loadingMessage, setLoadingMessage] = useState("");
  const [jobId, setJobId] = useState("");
  const [retry, setRetry] = useState(0);
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#ffffff");

  // Get User Tokens from Firestore
  useEffect(() => {
    if (auth.currentUser !== null) {
      const id = auth.currentUser.uid;
      getDoc(doc(db, "users", id)).then((docSnap) => {
        const tokensDb = docSnap.data().tokens;
        setTokens(tokensDb);
      });
    }
  }, [auth.currentUser]);

  // Update User Tokens on Firestore
  useEffect(() => {
    if (tokens !== null) {
      try {
        const docRef = updateDoc(doc(db, "users", auth.currentUser.uid), {
          tokens: tokens,
        });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
      console.log("tokens updated to:", tokens);
    }
  }, [tokens]);

  // Handle Events Functions
  const handleClear = () => {
    setResponse([]);
  };

  const handleDescription = (e, key) => {
    if (key === "1") {
      setPromptOne(e.target.value);
      setDescriptionCount(e.target.value.length);
    } else if (key === "2") {
      setPromptTwo(e.target.value);
      setDescriptionCountTwo(e.target.value.length);
    } else if (key === "3") {
      setPromptThree(e.target.value);
      setDescriptionCountThree(e.target.value.length);
    } else if (key === "4") {
      setPromptFour(e.target.value);
      setDescriptionCountFour(e.target.value.length);
    }

    const height = e.target.scrollHeight;
    if (height < 80) {
      setHeightCount(80);
    } else if (height > 80 && height < 200) {
      setHeightCount(e.target.scrollHeight);
    } else if (height > 200) setNameCount(1000);
  };

  const handleSubmit = (e) => {
    // Create and send prompt to OpenAI API
    const prompt = `0: ${promptOne} | 50: ${promptTwo} | 100: ${promptThree} | 150: ${promptFour}`;
    console.log(prompt);
    setLoading(true);

    //Process OpenAI API response (test - JSON Server)
    if (tokens === !null || tokens > 1900) {
      // setResponse([...responses,
      //     { id : responses.length, text : 'Generating Video (this may take a few minutes)'}
      //     ])
      setLoadingMessage([
        "Video Generating.... Please be patient, this may take a few minutes... Do not close the browser or refresh the page",
      ]);

      const deforum = {
        method: "POST",
        url: "https://us-central1-samurai-1cb94.cloudfunctions.net/api/deforum",
        // url: "http://localhost:8080/deforum",
        params: {
          prompt: prompt,
        },
      };
      axios.request(deforum).then((res) => {
        setJobId(res.data);
        console.log(jobId);
        const newTokens = tokens - 2000;
      });
    } else if (tokens == null) {
      setLoadingMessage("Please try again/ refresh page");
    } else if (tokens <= 0) {
      setLoadingMessage("Unfortunately you are out of Tokens :(");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (jobId !== "") {
        console.log(jobId);
        const deforumresult = {
          method: "POST",
          url: "https://us-central1-samurai-1cb94.cloudfunctions.net/api/deforumresult",
          //   url: "http://localhost:8080/deforumresult",
          params: {
            id: jobId,
          },
        };
        axios.request(deforumresult).then((res) => {
          const data = res.data;
          console.log(data);
          console.log("api call made");
          if (data.status !== "COMPLETED") {
            console.log(data.status);
            setRetry(retry + 1);
          } else {
            setSuccess("true");
            setLoadingMessage(
              'Right click and select "Save video as..." to download'
            );
            setLoading(false);
            console.log(data.output.video);
            console.log(data.status);
            const reply = data.output.video;
            //Output response to DOM
            setResponse([...responses, { id: responses.length, text: reply }]);

            // const newTokens = tokens - 2000
          }
        });
      }
    }, 30000);
  }, [jobId, retry]);

  return (
    <motion.div
      className="template-layout"
      variants={transitionVariants}
      initial="hidden"
      animate="visible"
    >
      <Sidebar />

      <div className="template-input">
        <div className="template-description">
          <img
            src={require(`./video.png`)}
            alt="logo"
            className="template-img"
            style={{ float: "left" }}
          />
          <div className="template-info">
            <h5>Video Generator</h5>
            <p>
              Let Samurai create AI generated videos for you from your written
              description
            </p>
          </div>
        </div>

        <div className="template-input-field">
          <h6>Describe the Scenes of Your Video</h6> <br />
          <p style={{ float: "right", maxLength: "150px" }}>
            {descriptionCount}/200
          </p>
          <h6>Scene 1</h6>
          <textarea
            onChange={(e) => handleDescription(e, "1")}
            maxLength={200}
            className="template-description-textarea txtarea"
            style={{ height: `${heightCount}px` }}
            placeholder="Describe the first scene. Add details such as: in the style of a well-known artist, art styles, portrait, etc"
          ></textarea>{" "}
          <br />
          <br />
          <p style={{ float: "right", maxLength: "150px" }}>
            {descriptionCountTwo}/200
          </p>
          <h6>Scene 2</h6>
          <textarea
            onChange={(e) => handleDescription(e, "2")}
            maxLength={200}
            className="template-description-textarea txtarea"
            style={{ height: `${heightCount}px` }}
            placeholder="Describe the second scene. Add details such as: in the style of a well-known artist, art styles, portrait, etc"
          ></textarea>{" "}
          <br />
          <br />
          <p style={{ float: "right", maxLength: "150px" }}>
            {descriptionCountThree}/200
          </p>
          <h6>Scene 3</h6>
          <textarea
            onChange={(e) => handleDescription(e, "3")}
            maxLength={200}
            className="template-description-textarea txtarea"
            style={{ height: `${heightCount}px` }}
            placeholder="Describe the third scene. Add details such as: in the style of a well-known artist, art styles, portrait, etc"
          ></textarea>{" "}
          <br />
          <br />
          <p style={{ float: "right", maxLength: "150px" }}>
            {descriptionCountFour}/200
          </p>
          <h6>Scene 4</h6>
          <textarea
            onChange={(e) => handleDescription(e, "4")}
            maxLength={200}
            className="template-description-textarea txtarea"
            style={{ height: `${heightCount}px` }}
            placeholder="Describe the last scene. Add details such as: in the style of a well-known artist, art styles, portrait, etc"
          ></textarea>{" "}
          <br />
          <br />
          <button onClick={(e) => handleSubmit(e)} className="btn">
            Generate
          </button>
        </div>
      </div>

      <div className="template-output">
        <h5>Response From Samurai AI</h5> <br />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>{loadingMessage}</div> <br />
          <FadeLoader
            color={color}
            loading={loading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
        <div id="image-ai">
          {responses.map((response) => (
            <video key={response.id} autoPlay controls>
              {" "}
              <source src={response.text} type="video/mp4" />{" "}
            </video>
          ))}
          {/* <video autoPlay controls> <source src={"https://14068d66ba387efac9ce5e4b1741bcf2.r2.cloudflarestorage.com/ai-api/09-23/18bfa8c8-2df7-4ed5-b9f9-a41f22403cb5.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=16b502c87564788383d52ec498a61a24%2F20230926%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20230926T110411Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=9a77bcc4098c5302e16cff100279f67415991c5fcd60523407b013e04a1fed21"} type='video/mp4'/> </video> */}
        </div>
        <br />
        <button onClick={() => handleClear()} className="btn-clear">
          Clear
        </button>
      </div>
    </motion.div>
  );
}
