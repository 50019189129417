import React from "react";

const ProductDescriptionSummary = () =>{
    return (
        <div className="template">
                <img src={require('../img/productdescription.png')} alt="logo" className="template-img" />
                <h5>Product Description</h5>
                <p>Captivating product descriptions for your e-commerce store, social media, emails, etc</p>
        </div>
    )
}

export default ProductDescriptionSummary