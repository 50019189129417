import React from 'react'
import TemplateStructure from './TemplateStructure'

export default function Cv() {
    const imgName = 'cv';
    const heading = 'Curriculum Vitae';
    const description = 'Generate a CV for your job applications';
    const inputOne = 'Your Name'
    const placeHolderOne = 'Jane Doe'
    const inputTwo = 'CV Contents'
    const placeHolderTwo = 'State your education, skills, work experiences, position applying for, etc'
    const inputThree = 'Target'
    const placeHolderThree = 'Name of company applying to (can be generic if left empty)'
    const aiResponse =  'This is an AI generated response'
    const maxTokens = 1000
    const temperature =  0.5
    

  return (
    <div>
        <TemplateStructure imgName={imgName} heading={heading} description={description} inputOne={inputOne} placeHolderOne={placeHolderOne} inputTwo={inputTwo} placeHolderTwo={placeHolderTwo}
        inputThree={inputThree} placeHolderThree={placeHolderThree} aiResponse={aiResponse} maxTokens={maxTokens} temperature={temperature}/>
    </div>
  )
}