import React, { useState, useEffect } from "react";
import Sidebar from "../../layout/Sidebar";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../../../config/fbConfig";
import { getAuth } from "firebase/auth";
import { getFirestore, getDoc, doc, updateDoc } from "firebase/firestore";
import axios from "axios";
import { motion } from "framer-motion";
import FadeLoader from "react-spinners/FadeLoader";

const transitionVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
  },
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
export default function Art() {
  // UseStates
  const [nameCount, setNameCount] = useState(0);
  const [descriptionCount, setDescriptionCount] = useState(0);
  const [toneCount, settoneCount] = useState(0);
  const [heightCount, setHeightCount] = useState(100);
  const [promptName, setPromptName] = useState("");
  const [promptDescription, setPromptDescription] = useState("");
  const [promptTone, setPromptTone] = useState("");
  const [responses, setResponse] = useState([]);
  const [tokens, setTokens] = useState(null);
  const [imgSize, setImgSize] = useState(null);
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#ffffff");

  var imgSelection = "";

  if (imgSize !== null) {
    imgSelection = `You have selected ${imgSize} image size`;
  }

  // Get User Tokens from Firestore
  useEffect(() => {
    if (auth.currentUser !== null) {
      const id = auth.currentUser.uid;
      getDoc(doc(db, "users", id)).then((docSnap) => {
        const tokensDb = docSnap.data().tokens;
        setTokens(tokensDb);
      });
    }
  }, [auth.currentUser]);

  // Update User Tokens on Firestore
  useEffect(() => {
    if (tokens !== null) {
      try {
        const docRef = updateDoc(doc(db, "users", auth.currentUser.uid), {
          tokens: tokens,
        });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
      console.log("tokens updated to:", tokens);
    }
  }, [tokens]);

  // Handle Events Functions
  const handleClear = () => {
    setResponse([]);
  };

  const handleClick = (e) => {
    setImgSize(e.target.id);
  };

  const handleDescription = (e) => {
    setDescriptionCount(e.target.value.length);
    setPromptDescription(e.target.value);
    const height = e.target.scrollHeight;
    if (height < 100) {
      setHeightCount(100);
    } else if (height > 100 && height < 1000) {
      setHeightCount(e.target.scrollHeight);
    } else if (height > 1000) setNameCount(1000);
  };

  const handleSubmit = (e) => {
    // Create and send prompt to OpenAI API
    const prompt = promptDescription;
    setLoading(true);

    //Process OpenAI API response (test - JSON Server)
    if (tokens === !null || tokens > 0) {
      setResponse([
        ...responses,
        { id: responses.length, text: "Loading Response" },
      ]);

      const openai = {
        method: "POST",
        url: "https://us-central1-samurai-1cb94.cloudfunctions.net/api/openai-image",
        // url: "http://localhost:8080/openai-image",
        params: {
          prompt: prompt,
          size: imgSize,
        },
      };
      axios.request(openai).then((res) => {
        const data = res.data;
        const reply = data.data[0].url;
        setLoading(false);
        //Output response to DOM
        setResponse([...responses, { id: responses.length, text: reply }]);
        if (imgSize == "256x256") {
          const newTokens = tokens - 480;
          setTokens(newTokens);
        } else if (imgSize == "512x512") {
          const newTokens = tokens - 520;
          setTokens(newTokens);
        } else if (imgSize == "1024x1024") {
          const newTokens = tokens - 600;
          setTokens(newTokens);
        }
      });
    } else if (tokens == null) {
      setResponse([
        ...responses,
        { id: responses.length, text: "Please try again/ refresh page" },
      ]);
    } else if (tokens <= 0) {
      setResponse([
        ...responses,
        {
          id: responses.length,
          text: "Unfortunately you are out of Tokens :(",
        },
      ]);
    }
  };

  return (
    <motion.div
      className="template-layout"
      variants={transitionVariants}
      initial="hidden"
      animate="visible"
    >
      <Sidebar />

      <div className="template-input">
        <div className="template-description">
          <img
            src={require(`./image.png`)}
            alt="logo"
            className="template-img"
            style={{ float: "left" }}
          />
          <div className="template-info">
            <h5>Image Generator</h5>
            <p>
              Let Samurai create artwork for you from your written description
            </p>
          </div>
        </div>

        <div className="template-input-field">
          <p style={{ float: "right", maxLength: "150px" }}>
            {descriptionCount}/800
          </p>
          <h6>Image Description</h6>
          <textarea
            onChange={(e) => handleDescription(e)}
            maxLength={800}
            className="template-description-textarea txtarea"
            style={{ height: `${heightCount}px` }}
            placeholder="Describe the image/scene, in the style of a well-known artist, art styles, portrait, etc"
          ></textarea>{" "}
          <br />
          <br />
          <h6>Size of Image</h6>
          <small>
            Please note: the larger the image size, the more tokens are used to
            generate the image
          </small>
          <div className="img-size-selection" onClick={(e) => handleClick(e)}>
            <button className="btn" id="256x256">
              256x256
            </button>
            <button className="btn" id="512x512">
              512x512
            </button>
            <button className="btn" id="1024x1024">
              1024x1024
            </button>
          </div>
          <br />
          <p>{imgSelection}</p>
          <br /> <br /> <br />
          <button onClick={(e) => handleSubmit(e)} className="btn">
            Generate
          </button>
        </div>
      </div>

      <div className="template-output">
        <h5>Response From Samurai AI</h5>
        <div id="image-ai">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {responses.map((response) => (
              <img src={response.text} key={response.id} alt="Loading"></img>
            ))}
            <FadeLoader
              color={color}
              loading={loading}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </div>
        <button onClick={() => handleClear()} className="btn-clear">
          Clear
        </button>
      </div>
    </motion.div>
  );
}
