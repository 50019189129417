import React, { Component, useState } from 'react'
import { initializeApp } from 'firebase/app'
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth'
import firebaseConfig from '../../config/fbConfig'
import { NavLink, useNavigate } from 'react-router-dom'
import Footer from '../layout/Footer'
import { motion } from 'framer-motion'

const transitionVariants = {
    hidden: {
        scale:0
    },
    visible: {
        scale: 1,
        transition:{duration:0.5}
    }
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

export default function SignIn() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()

  const handleChange = (e) => {
     if (e.target.id == 'email') {
      setEmail(e.target.value) 
     } else if (e.target.id == 'password'){
      setPassword(e.target.value)
     }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      e.target.reset();
      navigate('/templates')
      // ...
      })
      .catch((error) => {
      const errorCode = error.code;
      setErrorMessage(error.message.split('/')[1].split(')')[0]);
      });
  }

  return (
    <div>
    <motion.div className ="container"
      variants={transitionVariants} 
      initial="hidden"
      animate="visible"
    >
      <form onSubmit = {(e) => handleSubmit(e)} className="white">
          <h5 className="grey-text text-darken-3">Sign In</h5>
          <div className="input-field">
             <label htmlFor="email">Email</label> 
             <input type="email" id='email' onChange={(e) => handleChange(e)}/>
          </div>
          <div className="input-field">
             <label htmlFor="password">Password</label> 
             <input type="password" id='password' onChange={(e) => handleChange(e)}/>
          </div>
          <NavLink className='forgot-password' to='/resetpassword'>Forgot Password?</NavLink>
          <p className='sign-up'>Don't have an account? <NavLink to='/signup'>Sign Up</NavLink></p>
          <div className="error-message">{errorMessage}</div>
          <div className="input-field">
              <button className="btn z-depth-0">Login</button>
          </div> 
      </form>
      </motion.div>
      <Footer/>
    </div>
  )
}

