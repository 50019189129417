import React from "react";

const CompanyBio = () =>{
    return (
        <div className="template">
                <img src={require('../img/companybio.png')} alt="logo" className="template-img" />
                <h5>Company Bio</h5>
                <p>Generate a company bio that will put you on the radar</p>
        </div>
    )
}

export default CompanyBio