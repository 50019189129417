import React from "react";

const DocumentSummary = () =>{
    return (
        <div className="template">
                <img src={require('../img/document.png')} alt="logo" className="template-img" />
                <h5>Business Documents</h5>
                <p>Let Samurai help you create business documents (business plan, strategy document, etc)</p>
        </div>
    )
}

export default DocumentSummary