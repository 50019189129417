import React from 'react'
import Footer from '../layout/Footer'
import { motion } from 'framer-motion'

const transitionVariants = {
    hiddenLeft: {
        x:'-20vw'
    },
    hiddenRight: {
        x:'20vw'
    },
    visible: {
        y:0, 
        x:0, 
        transition:{duration:1}
    }
}

const hoverVariants = {
    hover:{
        scale: 1.1,
        originX: 0,
        transition:{
            type: 'spring', 
            stiffness: 300
        }
    }
}

export default function Home({authUser}) {
    const priceRoute = authUser ? '/templates' : '/signup'
  return (
    <div className='home'>
        <section id="hero">
        <div >
                    <img className= "video" src={require('../../video/samurai-landscape.jpg')} alt="samurai" />
        </div>
            <div className="hero-content">
                <motion.h1
                    variants={transitionVariants} 
                    initial={{y:-100, x:200}}
                    animate="visible"
                    ><strong>Samurai</strong> <br/>Your AI Companion</motion.h1> <br/>
                <motion.h5
                    variants={transitionVariants}
                    initial="hiddenLeft"
                    animate="visible"
                    >Where The Art of Creativity Meets The Precision of Artificial Intelligence</motion.h5>
                <motion.a 
                    variants={transitionVariants}
                    initial="hiddenRight"
                    animate="visible"
                    href="#features" className="cta-button btn">Get Started</motion.a>
            </div>
        </section>
        <section id="features">
            <div className="container">
            <h2>Features</h2>
            <ul>
                <motion.li
                    variants={transitionVariants}
                    initial="hiddenLeft"
                    whileInView="visible"
                ><div>
                        <img className= "video-ai" src={require('../../video/ai-copy.jpg')} alt="samurai" />
                    </div>
                    <motion.h5
                        variants={hoverVariants} 
                        whileHover="hover"
                    ><b>AI-powered copywriting</b><br/><br/> Let our AI technology generate persuasive marketing copy for your campaigns</motion.h5></motion.li>
                <motion.li
                    variants={transitionVariants}
                    initial="hiddenRight"
                    whileInView="visible"
                ><div>
                        <img className= "video-ai" src={require('../../video/ai-image.jpg')} alt="samurai" />
                    </div>
                    <motion.h5 
                        variants={hoverVariants} 
                        whileHover="hover"
                    ><b>AI creation tool</b><br/><br/> Empowering you to create engaging content effortlessly, making your life easier</motion.h5></motion.li>
                     <motion.li
                    variants={transitionVariants}
                    initial="hiddenLeft"
                    whileInView="visible"
                ><div>
                        <img className= "video-ai" src={require('../../video/ai-white.jpg')} alt="samurai" />
                    </div>
                    <motion.h5
                        variants={hoverVariants} 
                        whileHover="hover"
                    ><b>AI Image Generator</b><br/><br/> Create images using word descriptions</motion.h5></motion.li>
                    <motion.li
                    variants={transitionVariants}
                    initial="hiddenRight"
                    whileInView="visible"
                ><div>
                        <img className= "video-ai" src={require('../../video/ai-video.png')} alt="samurai" />
                    </div>
                    <motion.h5
                        variants={hoverVariants} 
                        whileHover="hover"
                    ><b>AI Video Generator</b><br/><br/> Generate AI videos using word descriptions</motion.h5></motion.li>
            </ul>
            <a href="#templates" className="cta-button btn">Explore Templates</a>
            </div>
        </section>
        <section id="templates">
            <div className="container">
            <h2>Templates</h2>
            <motion.h5
                variants={transitionVariants}
                initial="hiddenLeft"
                whileInView="visible"
            >Unlock your creative potential with our professionally designed templates:</motion.h5>
            <div className="template-block-small">
                <motion.h5 
                    variants={hoverVariants} 
                    whileInView={{scale: [1, 1.1, 1], originX:'center', transition:{delay:0.5, duration:1.5}}}
                ><img src={require('../workspace/templates/img/document.png')} alt="logo" className="template-img-home" />Business Documents</motion.h5>
                <motion.h5 
                    variants={hoverVariants} 
                    whileInView={{scale: [1, 1.1, 1], originX:'center', transition:{delay:2, duration:1.5}}}
                ><img src={require('../workspace/templates/img/companybio.png')} alt="logo" className="template-img-home" />Company Bio</motion.h5>
                <motion.h5 
                    variants={hoverVariants} 
                    whileInView={{scale: [1, 1.1, 1], originX:'center', transition:{delay:3.5, duration:1.5}}}
                ><img src={require('../workspace/templates/img/email.png')} alt="logo" className="template-img-home" />Email</motion.h5>
                <motion.h5 
                    variants={hoverVariants} 
                    whileInView={{scale: [1, 1.1, 1], originX:'center', transition:{delay:5, duration:1.5}}}
                ><img src={require('../workspace/templates/img/instagram.png')} alt="logo" className="template-img-home" />Instagram Caption</motion.h5>
                <motion.h5 
                    variants={hoverVariants} 
                    whileInView={{scale: [1, 1.1, 1], originX:'center', transition:{delay:6.5, duration:1.5}}}
                ><img src={require('../workspace/templates/img/presentation.png')} alt="logo" className="template-img-home" />Presentation</motion.h5>
                <motion.h5 
                    variants={hoverVariants} 
                    whileInView={{scale: [1, 1.1, 1], originX:'center', transition:{delay:8, duration:1.5}}}
                ><img src={require('../workspace/templates/img/facebook.png')} alt="logo" className="template-img-home" />Facebook Ad</motion.h5>
            </div>
            <br/><motion.h6
                variants={transitionVariants}
                initial="hiddenRight"
                whileInView="visible"
            >And many more...</motion.h6>
            <a href="#pricing" className="cta-button btn">Pricing</a>
            </div>
        </section>
        <section id="pricing">
            <div className="container">
            <h2>Pricing</h2>
            <h5>Select a plan that fits your needs:</h5> <br />
            <ul className='template-block-small'>
                <motion.li
                    variants={hoverVariants} 
                    initial = {{y: '-100px'}}
                    whileInView={{y: '0', transition:{delay:0.25, duration:0.1, type: 'spring', stiffness: '500', damping: '25'}}}
                    viewport={{ once: true }}
                ><h5><img src={require('../layout/img/basicplan.png')} alt="logo" className="template-img-home" />Basic: R350/month</h5></motion.li>
                <motion.li
                    variants={hoverVariants} 
                    initial = {{y: '-100px'}}
                    whileInView={{y: '0', transition:{delay:0.5, duration:10.1, type: 'spring', stiffness: '500', damping: '25'}}}
                    viewport={{ once: true }}
                ><h5><img src={require('../layout/img/proplan.png')} alt="logo" className="template-img-home" />Pro: R800/month</h5></motion.li>
                <motion.li
                    variants={hoverVariants} 
                    initial = {{y: '-100px'}}
                    whileInView={{y: '0', transition:{delay:0.75, duration:0.1, type: 'spring', stiffness: '500', damping: '25'}}}
                    viewport={{ once: true }}
                ><h5><img src={require('../layout/img/premiumplan.png')} alt="logo" className="template-img-home" />Premium: R1400/month</h5></motion.li>
            </ul>
            <br />
            <h6>Sign up today and receive 2000 tokens free!</h6>
            <a href={priceRoute} className="cta-button btn">Discover More</a>
            </div>
            <a href="#hero" className='cta-button return'><img src={require('../../video/icons8-return-24.png')} alt="logo" className='img-return'/></a>
        </section>
        <Footer/>
    </div>
  )
}
