// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth} from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


const firebaseConfig = {
  apiKey: "AIzaSyCmb3t_QoYwfaUg7y02rTp4qvovdD_xTg0",
  authDomain: "samurai-1cb94.firebaseapp.com",
  projectId: "samurai-1cb94",
  storageBucket: "samurai-1cb94.appspot.com",
  messagingSenderId: "823944103380",
  appId: "1:823944103380:web:53f77959e4c1ffeb0f0241",
  measurementId: "G-SXJQ3R85BQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)

export default firebaseConfig 


