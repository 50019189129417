import React from "react";

const CvSummary = () =>{
    return (
        <div className="template">
                <img src={require('../img/cv.png')} alt="logo" className="template-img" />
                <h5>Curriculum Vitae</h5>
                <p>Generate a CV for your job applications</p>
        </div>
    )
}

export default CvSummary