import React, { Component, useState } from 'react'
import { initializeApp } from 'firebase/app'
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import firebaseConfig from '../../config/fbConfig'
import { getFirestore, setDoc, doc } from "firebase/firestore";
import { NavLink, useNavigate } from 'react-router-dom';
import Footer from '../layout/Footer';
import { AnimatePresence, motion } from 'framer-motion'
import TermsModal from './TermsModal';

const transitionVariants = {
    hidden: {
        scale:0
    },
    visible: {
        scale: 1,
        transition:{duration:0.5}
    }
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)

export default function SignUp() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [tokens, setTokens] = useState(2000)
  const [errorMessage, setErrorMessage] = useState('')
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate()

  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        try {
          const docRef = setDoc(doc(db, "users", userCredential.user.uid), {
            email: email,
            name: firstName,
            surname: lastName,
            tokens: tokens,
            plan: 'Free Trial',
          });
          e.target.reset();
          sendEmailVerification(auth.currentUser)
          navigate('/templates')
        } catch (e) {

        }
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        setErrorMessage(error.message.split('/')[1].split(')')[0]);
      });   
  }     

  return (
    <div className='sign-pages'>
    <motion.div className ="container"
      variants={transitionVariants} 
      initial="hidden"
      animate="visible"
    >
      <form onSubmit = {(e) => handleSubmit(e)} className="white">
          <h5 className="grey-text text-darken-3">Sign Up</h5>
          <div className="input-field">
             <label htmlFor="firstName">First Name</label> 
             <input type="text" id='firstName' onChange={(e) => setFirstName(e.target.value)} required/>
          </div>
          <div className="input-field">
             <label htmlFor="lastName">Last Name</label> 
             <input type="text" id='lastName' onChange={(e) => setLastName(e.target.value)} required/>
          </div>
          <div className="input-field">
             <label htmlFor="email">Email</label> 
             <input type="email" id='email' onChange={(e) => setEmail(e.target.value)} required/>
          </div>
          <div className="input-field">
             <label htmlFor="password">Password</label> 
             <input type="password" id='password' onChange={(e) => setPassword(e.target.value)} required/>
          </div>
          <div>
            <label htmlFor="terms" >
            <input type="checkbox" id='terms' required/>
              <span>I accept the <motion.a
                onClick={() => (modalOpen ? close() : open())}
                className='pointer'
              >Terms and Conditions</motion.a> </span>
            </label> 
          </div>
          <p className='sign-up'>Already have an account? <NavLink to='/signin'>Sign In</NavLink></p>
          <div className="error-message">{errorMessage}</div>
          <div className="input-field">
              <button className="btn z-depth-0">Sign Up</button>
          </div> 
        </form>
      </motion.div>  
      <Footer/>
      <AnimatePresence
                        initial={false}
                        mode="wait"
                        onExitComplete={() => null}
        >
                        {modalOpen && <TermsModal modalOpen={modalOpen} handleClose={close} />}
        </AnimatePresence> 
    </div>
  )
}
