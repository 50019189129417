import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import Dashboard from "./components/workspace/dashboard/Dashboard";
import Chatbot from "./components/workspace/templates/ChatBot";
import Templates from "./components/workspace/templates/Templates";
import CompanyBio from "./components/workspace/templates/CompanyBio";
import Email from "./components/workspace/templates/Email";
import Instagram from "./components/workspace/templates/Instagram";
import ProductDescription from "./components/workspace/templates/ProductDescription";
import Presentation from "./components/workspace/templates/Presentation";
import FacebookAd from "./components/workspace/templates/FacebookAd";
import GoogleAd from "./components/workspace/templates/GoogleAd";
import TikTokCaption from "./components/workspace/templates/TikTokCaption";
import ProductNameGen from "./components/workspace/templates/ProductNameGen";
import Flyer from "./components/workspace/templates/Flyer";
import Twitter from "./components/workspace/templates/Twitter";
import ContentSummariser from "./components/workspace/templates/ContentSummariser";
import Document from "./components/workspace/templates/Document";
import Pricing from "./components/home/Pricing";
import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import Purchase from "./components/home/Purchase";
import PayReturn from "./components/home/PayReturn";
import PayCancel from "./components/home/PayCancel";
import PayNotify from "./components/home/PayNotify";
import ResetPassword from "./components/auth/ResetPassword";
import ProtectedRoutes from "./ProtectedRoutes";
import Home from "./components/home/Home";

// Handle users login state______________________________________________________________________________________
import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import firebaseConfig from "./config/fbConfig";
import TermsandServices from "./components/home/TermsandServices";
import PrivacyPolicy from "./components/home/PrivacyPolicy";
import RealEstate from "./components/workspace/templates/RealEstate";
import Letter from "./components/workspace/templates/Letter";
import Cv from "./components/workspace/templates/Cv";
import Verify from "./Verify";
import Art from "./components/workspace/art/Art";
import Blog from "./components/workspace/templates/Blog";
import Video from "./components/workspace/art/Video";
import ArtAlternate from "./components/workspace/art/ArtAlternate";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

function App() {
  const [authUser, setauthUser] = useState(null);
  // const location = useLocation()

  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        setauthUser(user);
      } else {
        setauthUser(null);
      }
    });
    return () => {
      listen();
    };
  }, []);

  // __________________________________________________________________________________________________________

  return (
    <BrowserRouter forceRefresh={true}>
      <div className="App">
        <Navbar authUser={authUser} />
        <Routes>
          <Route element={<ProtectedRoutes authUser={authUser} />}>
            <Route element={<Verify auth={auth} />}>
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route forceRefresh exact path="/chatbot" element={<Chatbot />} />
              <Route exact path="/templates" element={<Templates />} />
              <Route exact path="/companybio" element={<CompanyBio />} />
              <Route exact path="/email" element={<Email />} />
              <Route exact path="/instagram" element={<Instagram />} />
              <Route
                exact
                path="/productdescription"
                element={<ProductDescription />}
              />
              <Route exact path="/presentation" element={<Presentation />} />
              <Route exact path="/facebookad" element={<FacebookAd />} />
              <Route exact path="/googlead" element={<GoogleAd />} />
              <Route exact path="/tiktokcaption" element={<TikTokCaption />} />
              <Route
                exact
                path="/productnamegen"
                element={<ProductNameGen />}
              />
              <Route exact path="/flyer" element={<Flyer />} />
              <Route exact path="/tweeter" element={<Twitter />} />
              <Route
                exact
                path="/contentsummariser"
                element={<ContentSummariser />}
              />
              <Route exact path="/document" element={<Document />} />
              <Route
                exact
                path="/purchase"
                element={<Purchase authUser={authUser} />}
              />
              <Route exact path="/payreturn" element={<PayReturn />} />
              <Route exact path="/paycancel" element={<PayCancel />} />
              <Route exact path="/paynotify" element={<PayNotify />} />
              <Route exact path="/realestate" element={<RealEstate />} />
              <Route exact path="/letter" element={<Letter />} />
              <Route exact path="/cv" element={<Cv />} />
              <Route exact path="/art" element={<Art />} />
              <Route exact path="/artalt" element={<ArtAlternate />} />
              <Route exact path="/blog" element={<Blog />} />
              <Route exact path="/video" element={<Video />} />
            </Route>
          </Route>
          <Route exact path="/" element={<Home authUser={authUser} />} />
          <Route exact path="/pricing" element={<Pricing />} />
          <Route exact path="/signin" element={<SignIn />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/resetpassword" element={<ResetPassword />} />
          <Route
            exact
            path="/termsandservices"
            element={<TermsandServices />}
          />
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
