import React from "react";
import Footer from "../layout/Footer";

export default function PrivacyPolicy() {
  return(
<div>
        <div className="policies">
        <h2>Privacy Policy</h2>

    <h4>Information We Collect</h4>
    <p>We may collect personal identification information from Users in various ways, including when Users visit our Website, register on the Website, subscribe to our services, and in connection with other activities, features, or resources we make available on our Website. The information we may collect includes, but is not limited to:</p>
    <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Billing address</li>
        <li>Payment information</li>
    </ul>

    <h4>How We Use Collected Information</h4>
    <p>We may collect and use Users' personal information for the following purposes:</p>
    <ul>
        <li>To process subscriptions: We use the information you provide to process and manage your subscription to our services.</li>
        <li>To personalize user experience: We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Website.</li>
        <li>To improve our services: We continually strive to improve our services based on the feedback and information we receive from you.</li>
        <li>To send periodic emails: We may use the email address you provide to send you information and updates related to your subscription, as well as occasional company news, updates, and promotional material.</li>
    </ul>

    <h4>Protection of User Information</h4>
    <p>We adopt appropriate data collection, storage, and processing practices, as well as security measures, to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our Website.</p>

    <h4>Sharing Your Personal Information</h4>
    <p>We do not sell, trade, or rent Users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding our Users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.</p>

    <h4>Third-Party Websites</h4>
    <p>Users may find advertising or other content on our Website that links to the sites and services of our partners, suppliers, advertisers, sponsors, licensors, and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Website. Browsing and interaction on any other website, including websites that have a link to our Website, is subject to that website's own terms and policies.</p>

    <h4>Changes to This Privacy Policy</h4>
    <p>We have the discretion to update this Privacy Policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this Privacy Policy periodically and become aware of any modifications.</p>

    <h4>Contact Us</h4>
    <p>If you have any questions about this Privacy Policy, the practices of our Website, or your dealings with us, please contact us at:</p>
    <ul>
        <li>Email: support@aisamurai.co.za</li>
        <li>Phone: 081 237 2844</li>
    </ul>
    </div>
    <Footer/>
</div>
  )
}
