import React from 'react'

export default function PayReturn() {
  return (
    <div>
        <h4 className='templates-title'>Subscription</h4>
        <div className='pay-block'>
            <div className='plan'>
                <img src={require('../layout/img/paycomplete.avif')} alt="basic plan" className='pay-img'/> <br />
                <h4 className='pay-return'><b>Payment Successful</b></h4>
                <p>You can now access the workspace</p>
            </div>
        </div>
        </div>
  )
}
