import React from "react";

const EmailSummary = () =>{
    return (
        <div className="template">
                <img src={require('../img/email.png')} alt="logo" className="template-img" />
                <h5>Email</h5>
                <p>Generate emails about your company, new products, promotions, sales, etc</p>
        </div>
    )
}

export default EmailSummary