import React from 'react'
import TemplateStructureTwo from './TemplateStructureTwo'

export default function ContentSummariser() {
    const imgName = 'summariser';
    const heading = 'Content Summariser';
    const description = 'Summarise articles, blogs, documents, news reports, etc';
    const inputTwo = 'Content'
    const placeHolderTwo = 'Paste content'
    const inputThree = 'Keypoints'
    const placeHolderThree = 'Highlight keypoints that must be included in the summary'
    const aiResponse =  'This is an AI generated response'
    const maxTokens = 1000
    const temperature =  0.2
    

  return (
    <div>
        <TemplateStructureTwo imgName={imgName} heading={heading} description={description} inputTwo={inputTwo} placeHolderTwo={placeHolderTwo}
        inputThree={inputThree} placeHolderThree={placeHolderThree} aiResponse={aiResponse} maxTokens={maxTokens} temperature={temperature}/>
    </div>
  )
}