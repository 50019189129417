import { motion } from "framer-motion";
import React from "react";
import Backdrop from "../workspace/dashboard/Backdrop";

const dropIn = {
    hidden: {
        y: "-100vh",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        },
    },
    exit: {
        y: "100vh",
        opacity: 0,
    },
}

export default function TermsModal({ handleClose, text}) {
  return (
  <Backdrop onClick={handleClose}>
    <motion.div
        onClick={(e) => e.stopPropagation()}
        className="modal-custom terms"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
    >


        <h2>Terms and Conditions</h2>

        <h4>1. Subscription Plans</h4>
        <p>1.1 To access and use our services, you must subscribe to one of our available plans: Basic, Pro, or Premium. Each plan is subject to the corresponding pricing and token allocation described below:</p>
        <ul>
            <li>Basic Plan: R350 per month, including 20,000 tokens.</li>
            <li>Pro Plan: R800 per month, including 60,000 tokens.</li>
            <li>Premium Plan: R1400 per month, including 100,000 tokens.</li>
        </ul>
        <p>1.2 The subscription fee for each plan will be charged on the same date as the initial purchase was made, and subsequent payments will be charged on a monthly basis.</p>

        <h4>2. Tokens</h4>
        <p>2.1 Tokens are the currency used to access and utilize our AI-generated marketing copy services. Each token is approximately equivalent to one word.</p>
        <p>2.2 Tokens are renewed after each successful payment. Any unused tokens from the previous month will be forfeited and cannot be carried over to the next month.</p>
        <p>2.3 Tokens are charged for both the request/prompt you make as well as the response from the AI</p>

        <h4>3. Usage of Services</h4>
        <p>3.1 Our services allow you to generate marketing copy using AI technology. While we strive to provide accurate and reliable results, we do not guarantee the accuracy or effectiveness of the generated content. It is your responsibility to review and modify the generated copy as necessary.</p>
        <p>3.2 You acknowledge that our AI-generated content is intended to assist and facilitate your marketing efforts, but final responsibility for compliance with applicable laws, regulations, and ethical standards rests with you. We are not liable for any legal or ethical issues arising from the use of our services.</p>

        <h4>4. Cancellation and Unsubscribing</h4>
        <p>4.1 You have the right to unsubscribe from our services at any time. However, by unsubscribing, you forfeit any remaining tokens and will no longer be able to use our service.</p>
        <p>4.2 To unsubscribe, you must follow the cancellation process provided on our Website or contact our customer support.</p>
        <p>4.3 In the event of cancellation, you will no longer have access to any generated marketing copy or related features. It is your responsibility to retain any copies of the content you wish to keep before unsubscribing.</p>

        <h4>5. Privacy and Data Protection</h4>
        <p>5.1 Your privacy is important to us. We collect, store, and process your personal information in accordance with our Privacy Policy, which you can find on our Website.</p>
        <p>5.2 By using our services, you consent to the collection, storage, and processing of your personal information as described in our Privacy Policy.</p>

        <h4>6. Intellectual Property</h4>
        <p>6.1 All intellectual property rights related to our AI technology, services, and Website, including but not limited to copyrights, trademarks, and patents, are owned by us or our licensors. You are granted a limited, non-exclusive, and non-transferable license to use our services solely for your own business purposes.</p>
        <p>6.2 You agree not to reproduce, modify, distribute, or create derivative works based on our services without our prior written consent.</p>

        <h4>7. Amendments and Modifications</h4>
        <p>7.1 We reserve the right to modify, update, or revise these Terms at any time, with or without notice. Any changes will be effective immediately upon posting the updated Terms on our Website. It is your responsibility to review the Terms periodically for any updates or changes.</p>
        <p>7.2 It is your responsibility to review these Terms periodically to stay informed about any changes.</p>
        <p>7.3 By continuing to use our Services after any modifications to the Terms, you acknowledge and agree to be bound by the updated Terms.</p>

        <h4>8. Termination</h4>
        <p>8.1 We reserve the right to terminate or suspend your access to our Services without prior notice if we believe that you have violated these Terms or engaged in any fraudulent, abusive, or illegal activities.</p>
        <p>8.2 Upon termination, you must immediately cease using our Services, and any remaining tokens will be forfeited.</p>

        <h4>9. Indemnification</h4>
        <p>9.1 You agree to indemnify, defend, and hold us harmless from any claims, losses, damages, liabilities, and expenses, including reasonable attorneys' fees, arising out of or relating to your use of our Services or any violation of these Terms.</p>
    
        <h4>10. Severability</h4>
        <p>10.1 If any provision of these Terms is deemed invalid or unenforceable, the remaining provisions shall remain in full force and effect.</p>

        <h4>11. Entire Agreement</h4> 
        <p>11.1 These Terms constitute the entire agreement between you and us regarding the use of our Services and supersede any prior or contemporaneous agreements, understandings, or representations.</p> 

        <h4>12. Governing Law and Jurisdiction</h4> 
        <p>12.1 These Terms shall be governed by and construed in accordance with the laws of South Africa.</p> 
        <p>12.2 Any disputes arising out of or relating to these Terms shall be subject to the exclusive jurisdiction of the courts located in South Africa.</p> 

        <h4>13. Contact Us</h4> 
        <p>13.1 If you have any questions or concerns regarding these Terms, please contact us at:</p> 
        <ul>
            <li>- Email: info@samurai.ai</li>
            <li>- Phone: 081 237 2844</li>
        </ul>

        <div className="buttons">
            <button onClick={handleClose} id="cancel" className="btn">Close</button>
        </div>
                
    </motion.div>

  </Backdrop>
  )
}
