import React from 'react'
import TemplateStructure from './TemplateStructure'

export default function RealEstate() {
    const imgName = 'realestate';
    const heading = 'Real Estate Listing';
    const description = 'Write charming property descriptions for real estate listings';
    const inputOne = 'Type of Property'
    const placeHolderOne = 'Commercial, Town House, Residential, etc'
    const inputTwo = 'Property Description'
    const placeHolderTwo = 'Two bedroom, two baths, 56 sqm, POI, etc'
    const inputThree = 'Target'
    const placeHolderThree = 'Investors, First Time Home Owners, etc'
    const aiResponse =  'This is an AI generated response'
    const maxTokens = 1000
    const temperature =  1
    

  return (
    <div>
        <TemplateStructure imgName={imgName} heading={heading} description={description} inputOne={inputOne} placeHolderOne={placeHolderOne} inputTwo={inputTwo} placeHolderTwo={placeHolderTwo}
        inputThree={inputThree} placeHolderThree={placeHolderThree} aiResponse={aiResponse} maxTokens={maxTokens} temperature={temperature}/>
    </div>
  )
}