import React from 'react'
import TemplateStructure from './TemplateStructure'

export default function Email() {
    const imgName = 'email';
    const heading = 'Email';
    const description = 'Generate emails about your company, new products, promotions, sales, etc';
    const inputOne = 'Name of Product/Company/Service'
    const placeHolderOne = 'B-Loud Bluetooth Speaker'
    const inputTwo = 'Email Description'
    const placeHolderTwo = 'Offering customers a stereo bluetooth speaker at a discounted price. The speaker uses state of the art sound engineering to deliver the best audio experience to listeners'
    const inputThree = 'Tone'
    const placeHolderThree = 'Funny, professional, in the tone of Steve Jobs'
    const aiResponse =  'This is an AI generated response'
    const maxTokens = 1000
    const temperature =  0.7
    

  return (
    <div>
        <TemplateStructure imgName={imgName} heading={heading} description={description} inputOne={inputOne} placeHolderOne={placeHolderOne} inputTwo={inputTwo} placeHolderTwo={placeHolderTwo}
        inputThree={inputThree} placeHolderThree={placeHolderThree} aiResponse={aiResponse} maxTokens={maxTokens} temperature={temperature}/>
    </div>
  )
}