import React from "react";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  return (
    <div className="sidebar side-grid">
      <ul className="side-list">
        <div>
          <img
            src={require("../layout/img/dashboard.png")}
            alt="logo"
            className="sidebar-img"
          />
          <NavLink to="/dashboard" id="sidebar">
            {" "}
            <li>Dashboard</li>
          </NavLink>
        </div>

        {/* <li><NavLink to='/create'>Project</NavLink></li> */}
        <div>
          <img
            src={require("../layout/img/templates.png")}
            alt="logo"
            className="sidebar-img"
          />
          <NavLink to="/templates" id="sidebar">
            <li>Templates</li>
          </NavLink>
        </div>
        <div>
          <img
            src={require("../layout/img/image.png")}
            alt="logo"
            className="sidebar-img"
          />
          <NavLink to="/art" id="sidebar">
            <li>Image Generator</li>
          </NavLink>
        </div>
        {/* <div>
                    <img src={require('../layout/img/image.png')} alt="logo" className="sidebar-img" />
                    <NavLink to='/artalt' id='sidebar'><li>Image Generator Alt</li></NavLink>
                </div> */}
        {/* <div>
          <img
            src={require("../layout/img/video.png")}
            alt="logo"
            className="sidebar-img"
          />
          <NavLink to="/video" id="sidebar">
            <li>Video Generator</li>
          </NavLink>
        </div> */}
        {/* <li><NavLink to='/favorites'>Favorites</NavLink></li> */}
        {/* <img src={require('../layout/img/chatbot.png')} alt="logo" className="sidebar-img" /> */}
        {/* <NavLink to='/chatbot' id='sidebar'><li>Chatbot</li></NavLink> */}
      </ul>
    </div>
  );
};

export default Sidebar;
