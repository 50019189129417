import React, { useEffect, useState } from "react";
import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import firebaseConfig from '../../config/fbConfig'

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

const AuthDetails = () => {
    const [authUser, setauthUser] = useState(null)
    
    useEffect(() => {
        const listen = onAuthStateChanged(auth, (user) => {
            if (user) {
                setauthUser(user)
            } else{
                setauthUser(null);
            }
        });
        return () => {
            listen();
        }
    }, [])
    // return(
        // <div>{ authUser? <p>{`Signed In as' ${authUser.email}`}</p> : <p>Signed Out</p> }</div>
    // )
    }

export default AuthDetails