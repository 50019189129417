import React from "react";

const TikTokCaptionSummary = () =>{
    return (
        <div className="template">
                <img src={require('../img/tiktokblack.png')} alt="logo" className="template-img" />
                <h5>TikTok Caption</h5>
                <p>Gain popularity using our TikTok caption generator </p>
        </div>
    )
}

export default TikTokCaptionSummary