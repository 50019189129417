import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {

    return (
      <div className="footer">
        <div style={{float: 'right', paddingRight: '1%'}}>
              <h5 className="white-text">Social Media</h5>
              <ul className="footer-links">
                {/* <li><a className="grey-text text-lighten-3" href="https://icons8.com/icons/set/linkedin"><img src={require('../workspace/templates/img/linkedin.png')} className="footer-img"/>LinkedIn</a></li> */}
                <li><a className="grey-text text-lighten-3" href="https://www.facebook.com/profile.php?id=100093484928616&mibextid=ZbWKwL"><img src={require('../workspace/templates/img/facebook.png')} className="footer-img"/>Facebook</a></li>
                <li><a className="grey-text text-lighten-3" href="https://instagram.com/samurai_ai_sa?igshid=NGExMmI2YTkyZg=="><img src={require('../workspace/templates/img/instagram.png')} className="footer-img"/>Instagram</a></li>
                <li><a className="grey-text text-lighten-3" href="https://twitter.com/samurai_ai_sa?t=SIoTlYt82k75N0CbOLsdew&s=09"><img src={require('../workspace/templates/img/twitter.png')} className="footer-img"/>Twitter</a></li>
              </ul>
            </div>
            <div style={{paddingLeft:'1%'}}>
              <h5 className="white-text">Contact Us</h5>
              <p className="grey-text text-lighten-4">Email: support@aisamurai.co.za</p>
              <p className="grey-text text-lighten-4">Tel: 081 237 2844</p>
              <ul className="terms-links">
              <li className="footer-links"><NavLink to='/termsandservices' className="grey-text text-lighten-3 pointer">Terms and Conditions</NavLink></li>
              <li className="footer-links"><NavLink to='/privacypolicy' className="grey-text text-lighten-3 pointer">Privacy Policy</NavLink></li>
              </ul>
            </div>
      </div>     
    )
}

export default Footer
