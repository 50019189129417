import { motion } from "framer-motion";
import Backdrop from "./Backdrop";
import React from "react";
import { NavLink } from "react-router-dom";

const dropIn = {
    hidden: {
        y: "-100vh",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        },
    },
    exit: {
        y: "100vh",
        opacity: 0,
    },
}

export default function UpgradeModal({ handleClose, text}) {
  return (
  <Backdrop onClick={handleClose}>
    <motion.div
        onClick={(e) => e.stopPropagation()}
        className="modal-custom"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
    >
                        <h2>Upgrade Your Plan</h2>
                        <p>To upgrade your existing plan, please contact us via phone or email:</p>
                        <ul>
                            <li>Phone: <strong>081 237 2844</strong></li>
                            <li>Email: <strong>support@aisamurai.co.za</strong></li>
                        </ul>
                        <p>We will be happy to assist you with the upgrade process.</p>
                        <p>If you are on the free trial, you may <NavLink to='/purchase'>purchase a subscription by clicking this link</NavLink></p>
                        <div className="buttons">
                            <button onClick={handleClose} id="cancel" className="btn">Close</button>
                        </div>
                
    </motion.div>

  </Backdrop>
  )
}
