import React from 'react'
import TemplateStructure from './TemplateStructure'

export default function Document() {
    const imgName = 'document';
    const heading = 'Company Document';
    const description = 'Let Samurai help you create business documents';
    const inputOne = 'Type of Document'
    const placeHolderOne = 'Business Plan, Strategy Document, T&Cs, etc '
    const inputTwo = 'Company Description'
    const placeHolderTwo = 'Samurai was founded in 2023 and offers AI services in the marketing field.'
    const inputThree = 'Target'
    const placeHolderThree = 'Investors, Partners, etc'
    const aiResponse =  'This is an AI generated response'
    const maxTokens = 1000
    const temperature =  0.7
    

  return (
    <div>
        <TemplateStructure imgName={imgName} heading={heading} description={description} inputOne={inputOne} placeHolderOne={placeHolderOne} inputTwo={inputTwo} placeHolderTwo={placeHolderTwo}
        inputThree={inputThree} placeHolderThree={placeHolderThree} aiResponse={aiResponse} maxTokens={maxTokens} temperature={temperature}/>
    </div>
  )
}