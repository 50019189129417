import React, { Component} from "react";
import Sidebar from "../../layout/Sidebar";

class Chatbot extends Component {
    render(){
        return(
        <div className="chatbot">
            <Sidebar/>
            <div className="chat-input-holder">
                <textarea rows={1} className="chat-input-textarea"></textarea>        
            </div>
            <div className="chat-log">
                <div className="message-format">
                    <div className="chat-message">
                        <div className="avatar">
                        
                        </div>
                        <div className="message">
                            Hello World!
                        </div>
                    </div>
                </div>
                <div className="message-format gpt3">
                    <div className="chat-message gpt3">
                        <img src={require('./img/avatar.png')} alt="AI" className="gpt3 avatar"/>   
                        <div className="message">
                            I am an AI
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
        
    }
}

export default Chatbot