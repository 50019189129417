import React from "react";

const InstagramSummary = () =>{
    return (
        <div className="template">
                <img src={require('../img/instagram.png')} alt="logo" className="template-img" />
                <h5>Instagram Caption</h5>
                <p>Create captivating IG captions</p>
        </div>
    )
}

export default InstagramSummary