import React from 'react'
import SignIn from './components/auth/SignIn';
import { Outlet } from 'react-router-dom';



export default function ProtectedRoutes({authUser}) {
    const isAuth = authUser;
  return isAuth ? <Outlet /> : <SignIn />;
}
