import React from "react";
import ChatBotSummary from "./templateSummaries/ChatBotSummary";
import Sidebar from "../../layout/Sidebar";
import DocumentTemplate from "./templateSummaries/DocumentSummary";
import { NavLink } from "react-router-dom";
import CompanyBioSummary from "./templateSummaries/CompanyBioSummary";
import EmailSummary from "./templateSummaries/EmailSummary";
import InstagramSummary from "./templateSummaries/InstagramSummary";
import ProductDescriptionSummary from "./templateSummaries/ProductDescriptionSummary";
import PresentationSummary from "./templateSummaries/PresentationSummary";
import FacebookAdSummary from "./templateSummaries/FacebookAdSummary";
import GoogleAdSummary from "./templateSummaries/GoogleAdSummary";
import TikTokCaptionSummary from "./templateSummaries/TikTokCaptionSummary";
import ProductNameGenSummary from "./templateSummaries/ProductNameGenSummary";
import TwitterSummary from "./templateSummaries/TwitterSummary";
import FlyerSummary from "./templateSummaries/FlyerSummary";
import ContentSummariserSummary from "./templateSummaries/ContentSummariserSummary";
import { motion } from 'framer-motion'
import RealEstateSummary from "./templateSummaries/RealEstateSummary";
import LetterSummary from "./templateSummaries/LetterSummary";
import CvSummary from "./templateSummaries/CvSummary";
import BlogSummary from "./templateSummaries/BlogSummary";

const transitionVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition:{duration:0.5}
    }
}

const Templates = () =>{
    return (
        <div>
            <Sidebar/>
            <motion.div className="templates"
                variants={transitionVariants} 
                initial="hidden"
                animate="visible"
            >
                <div className="templates-title"><h4>Templates</h4></div>
                <div className="template-block">
                    <div> <NavLink to='/document'> <DocumentTemplate/> </NavLink></div>
                    <div> <NavLink to='/companybio'> <CompanyBioSummary/> </NavLink></div>
                    <div> <NavLink to='/email'> <EmailSummary/> </NavLink></div>
                    <div> <NavLink to='/instagram'> <InstagramSummary/> </NavLink></div>
                    <div> <NavLink to='/productdescription'> <ProductDescriptionSummary/> </NavLink></div>
                    <div> <NavLink to='/presentation'> <PresentationSummary/> </NavLink></div>
                    <div> <NavLink to='/facebookad'> <FacebookAdSummary/> </NavLink></div>
                    <div> <NavLink to='/googlead'> <GoogleAdSummary/> </NavLink></div>
                    <div> <NavLink to='/tiktokcaption'> <TikTokCaptionSummary/> </NavLink></div>
                    <div> <NavLink to='/productnamegen'> <ProductNameGenSummary/> </NavLink></div>
                    <div> <NavLink to='/flyer'> <FlyerSummary/> </NavLink></div>
                    <div> <NavLink to='/tweeter'> <TwitterSummary/> </NavLink></div>
                    <div> <NavLink to='/contentsummariser'> <ContentSummariserSummary/> </NavLink></div>
                    <div> <NavLink to='/realestate'> <RealEstateSummary/> </NavLink></div>
                    <div> <NavLink to='/letter'> <LetterSummary/> </NavLink></div>
                    <div> <NavLink to='/cv'> <CvSummary/> </NavLink></div>
                    <div> <NavLink to='/blog'> <BlogSummary/> </NavLink></div>
                </div>
            </motion.div>
        </div>               
    )
}

export default Templates