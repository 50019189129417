import React from 'react'
import TemplateStructureTwo from './TemplateStructureTwo'

export default function Instagram() {
    const imgName = 'instagram';
    const heading = 'Instagram Caption';
    const description = 'Create captivating IG captions';
    const inputTwo = 'Post Description'
    const placeHolderTwo = 'Swimming under waterfalls in Bali'
    const inputThree = 'Tone'
    const placeHolderThree = 'Funny, professional, in the tone of Steve Jobs'
    const aiResponse =  'This is an AI generated response'
    const maxTokens = 100
    const temperature =  1.8
        

  return (
    <div>
        <TemplateStructureTwo imgName={imgName} heading={heading} description={description} inputTwo={inputTwo} placeHolderTwo={placeHolderTwo}
        inputThree={inputThree} placeHolderThree={placeHolderThree} aiResponse={aiResponse} maxTokens={maxTokens} temperature={temperature}/>
    </div>
  )
}