import React from 'react'
import TemplateStructure from './TemplateStructure'

export default function Flyer() {
    const imgName = 'flyerwhite';
    const heading = 'Flyer';
    const description = 'Create content for flyers';
    const inputOne = 'Name of Product/Company/Service'
    const placeHolderOne = 'Dj Smoke Birthday Bash'
    const inputTwo = 'Description of Product/Company/Service'
    const placeHolderTwo = 'Nightclub event hosted by Dj Smoke. Entrance of R150pp'
    const inputThree = 'Tone'
    const placeHolderThree = 'Exciting, energetic, etc'
    const aiResponse =  'This is an AI generated response'
    const maxTokens = 1000
    const temperature =  1

  return (
    <div>
        <TemplateStructure imgName={imgName} heading={heading} description={description} inputOne={inputOne} placeHolderOne={placeHolderOne} inputTwo={inputTwo} placeHolderTwo={placeHolderTwo}
        inputThree={inputThree} placeHolderThree={placeHolderThree} aiResponse={aiResponse} maxTokens={maxTokens} temperature={temperature}/>
    </div>
  )
}