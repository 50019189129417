import { motion } from "framer-motion";
import Backdrop from "./Backdrop";
import React, { useState } from "react";
import axios from "axios";

const dropIn = {
    hidden: {
        y: "-100vh",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        },
    },
    exit: {
        y: "100vh",
        opacity: 0,
    },
}

export default function CancelSubModal({ handleClose, text, uid}) {

    const [unsubResult, setUnsubResult] = useState('')

    const handleUnsub = e => {
        e.preventDefault();
        const options = {
            method: 'GET',
            url: 'https://us-central1-samurai-1cb94.cloudfunctions.net/api/cancelsubmanual',
            params: {uid: uid }
        }

        axios.request(options).then(res => {
            setUnsubResult(res.data); 
        })
    }
console.log(unsubResult)
    if (unsubResult !== 'successful') {
        return (
            <Backdrop onClick={handleClose}>
              <motion.div
                  onClick={(e) => e.stopPropagation()}
                  className="modal-custom"
                  variants={dropIn}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
              >
                          <h2>Cancel Subscription</h2>
                          <p>Are you sure you want to cancel your subscription?</p>
                          <p><strong>Important:</strong> If you cancel your subscription, all your remaining tokens will be forfeited and you will no longer be able to continue using the service.</p>
                          <p>To use the service again, you will need to purchase a new subscription.</p>
                          <div className="buttons">
                              <button onClick={handleClose} id="cancel" className="btn">Keep Subscription</button>
                              <button onClick={handleUnsub} className="btn">Confirm</button>
                          </div>
                          
              </motion.div>
          
            </Backdrop>
            )   
    } else if (unsubResult == 'successful') {
        return (
            <Backdrop onClick={handleClose}>
              <motion.div
                  onClick={(e) => e.stopPropagation()}
                  className="modal-custom"
                  variants={dropIn}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
              >
                          <h2>Cancel Subscription</h2>
                          <p>You have successfully unsubscribed</p>
                          <div className="buttons">
                              <button onClick={handleClose} id="cancel" className="btn">Close</button>
                          </div>
                          
              </motion.div>
          
            </Backdrop>
            )
    }
}
