import React from "react";

const FacebookAdSummary = () =>{
    return (
        <div className="template">
                <img src={require('../img/facebook.png')} alt="logo" className="template-img" />
                <h5>Facebook Ad</h5>
                <p>Create ads for your Facebook page</p>
        </div>
    )
}

export default FacebookAdSummary