import React, { Component } from 'react'
import { initializeApp } from 'firebase/app'
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth'
import firebaseConfig from '../../config/fbConfig'
import AuthDetails from './AuthDetails'
import authUser from './AuthDetails'
import Footer from '../layout/Footer'
import { motion } from 'framer-motion'

const transitionVariants = {
    hidden: {
        scale:0
    },
    visible: {
        scale: 1,
        transition:{duration:0.5}
    }
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)


class ResetPassword extends Component {
    state = {
      email: '',
      errorMessage: '',
      successMessage: '',
    }
    handleChange = (e) => {
      this.setState({
       [e.target.id]: e.target.value 
      })
    }
    handleSubmit = (e) => {
        e.preventDefault()
        const auth = getAuth();
        sendPasswordResetEmail(auth, this.state.email)
            .then(() => {
            this.state.successMessage = `An email has been sent to ${this.state.email} to reset your password`
            this.forceUpdate()
            e.target.reset();
            })
            .catch((error) => {
            const errorCode = error.code;
            this.state.errorMessage = error.message.split('/')[1].split(')')[0];
            this.forceUpdate()
            });
    }
  render() {
    return (
      <div>
      <motion.div className ="container"
        variants={transitionVariants} 
        initial="hidden"
        animate="visible"
      >
        <form onSubmit = {this.handleSubmit} className="white">
            <h5 className="grey-text text-darken-3">Reset Password</h5>
            <h6>Please enter the email address linked to your account</h6>
            <div className="input-field">
               <label htmlFor="email">Email</label> 
               <input type="email" id='email' onChange={this.handleChange}/>
            </div>
            <div className="error-message">{this.state.errorMessage}</div>
            <div className="success-message">{this.state.successMessage}</div>
            <div className="input-field">
                <button className="btn z-depth-0">Reset Password</button>
            </div> 
        </form>
      </motion.div>
      <Footer/>
      </div>
    )
  }
}

export default ResetPassword