import React from 'react'

export default function PayCancel() {
  return (
    <div>
        <h4 className='templates-title'>Subscription</h4>
        <div className='pay-block'>
            <div className='plan'>
                <img src={require('../layout/img/paycancel.jpg')} alt="basic plan" className='pay-img'/> <br />
                <h4 className='pay-cancel'><b>Payment Cancelled</b></h4>
                <p>Please retry payment</p>
            </div>
        </div>
        </div>
  )
}
