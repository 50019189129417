import React, { useState } from "react";
import { getAuth, sendEmailVerification } from "firebase/auth";

export default function VerifyMessage({auth}) {

    const [click, setClick] = useState(true)
    const [error, setError] = useState('')

    const verifyEmail = () => {
            sendEmailVerification(auth.currentUser)
            setClick(false)     
    }

  return(
  <div className="verify">
        <h2>Please Verify Your Email Address</h2>
        <p>In order to use this service you need to verify your email address</p>
        <p>If you have done this already, please refresh the page</p>
        <p>Haven't received a verification email?</p>
        <p>{error}</p>
        {
            click? <button  className="btn" id="verify-btn" onClick={verifyEmail}>Resend verification email</button> : <p className="pay-return">Verification email sent</p>
        }
  </div>
  )
}
