import React from "react";
import { Link } from "react-router-dom";
import SignedInLinks from "./SignedInLinks";
import SignedOutLinks from "./SignedOutLinks";

const Navbar = ({authUser}) => {
    const links = authUser? <SignedInLinks authUser = {authUser}/> : <SignedOutLinks/>
    return (
        <nav className="nav-wrapper black">
            <div className="nav-logo">
                <ul className="brand-logo left">
                    <Link to='/'><img src={require('../workspace/templates/img/avatar.png')} alt="logo" className="logo-img"/> Samurai </Link>
                </ul>
            </div>
            <ul className="right navbar-links">
                { links }
            </ul>
        </nav>       
    )
}

export default Navbar
