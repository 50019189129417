import React, { Component, useState } from "react";
import { NavLink } from "react-router-dom";
import { initializeApp } from 'firebase/app'
import { getAuth, signOut } from 'firebase/auth'
import firebaseConfig from '../../config/fbConfig'
import { getFirestore } from "firebase/firestore"
import { getDoc, doc } from "firebase/firestore"

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)

export default function SignedInLinks() {

  const [userName, setUserName] = useState('')

 const handleClick = (e) => {
    e.preventDefault()
    signOut(auth).then(() => {
    
    }).catch((error) => {
    console.log(error)
    });
  }

  if (auth.currentUser !== null) {
    const id = auth.currentUser.uid
    getDoc(doc(db, "users", id)).then(docSnap => {
    const user = docSnap.data().name[0] + docSnap.data().surname[0]
    setUserName(user)
  })}

  return (
    <ul className="right">
        <li><NavLink id="nl" to='/purchase'>Purchase Plan</NavLink></li>
        <li><NavLink id="nl" to='/templates'>Workspace</NavLink></li>
        <li onClick={handleClick}><NavLink to='/'>Log Out</NavLink></li>
        <li><NavLink id="nl" to='/'className='btn btn-floating btn-shadow red darken-2'>{userName}</NavLink></li>
    </ul> 
  )
}

