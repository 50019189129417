import React, { useState, useEffect } from "react";
import Sidebar from "../../layout/Sidebar";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../../../config/fbConfig";
import { getAuth } from "firebase/auth";
import { getFirestore, getDoc, doc, updateDoc } from "firebase/firestore";
import axios from "axios";
import { motion } from "framer-motion";
import FadeLoader from "react-spinners/FadeLoader";
import { set } from "lodash";

const transitionVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
  },
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
export default function TemplateStructure({
  imgName,
  heading,
  description,
  inputOne,
  placeHolderOne,
  inputTwo,
  placeHolderTwo,
  inputThree,
  placeHolderThree,
  maxTokens,
  temperature,
}) {
  // UseStates
  const [nameCount, setNameCount] = useState(0);
  const [descriptionCount, setDescriptionCount] = useState(0);
  const [toneCount, settoneCount] = useState(0);
  const [heightCount, setHeightCount] = useState(100);
  const [promptName, setPromptName] = useState("");
  const [promptDescription, setPromptDescription] = useState("");
  const [promptTone, setPromptTone] = useState("");
  const [responses, setResponse] = useState([]);
  const [tokens, setTokens] = useState(null);
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#ffffff");

  // Get User Tokens from Firestore
  useEffect(() => {
    if (auth.currentUser !== null) {
      const id = auth.currentUser.uid;
      getDoc(doc(db, "users", id)).then((docSnap) => {
        const tokensDb = docSnap.data().tokens;
        setTokens(tokensDb);
      });
    }
  }, [auth.currentUser]);

  // Update User Tokens on Firestore
  useEffect(() => {
    if (tokens !== null) {
      try {
        const docRef = updateDoc(doc(db, "users", auth.currentUser.uid), {
          tokens: tokens,
        });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
      console.log("tokens updated to:", tokens);
    }
  }, [tokens]);

  // Handle Events Functions
  const handleClear = () => {
    setResponse([]);
  };

  const handleName = (e) => {
    setNameCount(e.target.value.length);
    setPromptName(e.target.value);
  };

  const handleDescription = (e) => {
    setDescriptionCount(e.target.value.length);
    setPromptDescription(e.target.value);
    const height = e.target.scrollHeight;
    if (height < 100) {
      setHeightCount(100);
    } else if (height > 100 && height < 1000) {
      setHeightCount(e.target.scrollHeight);
    } else if (height > 1000) setNameCount(1000);
  };

  const handleTone = (e) => {
    settoneCount(e.target.value.length);
    setPromptTone(e.target.value);
  };

  const handleSubmit = (e) => {
    // Create and send prompt to OpenAI API
    const prompt = `Create a ${heading}. The ${inputOne} is ${promptName}. The ${inputTwo} is: ${promptDescription}.  The ${inputThree} should be ${promptTone}.`;
    setLoading(true);

    //Process OpenAI API response (test - JSON Server)
    if (tokens === !null || tokens > 0) {
      setResponse([
        ...responses,
        { id: responses.length, text: "Loading Response" },
      ]);

      const openai = {
        method: "POST",
        url: "https://us-central1-samurai-1cb94.cloudfunctions.net/api/openai",
        // url: "http://localhost:8080/openai",
        params: {
          prompt: prompt,
          maxTokens: maxTokens,
          temperature: temperature,
        },
      };
      axios.request(openai).then((res) => {
        const data = res.data;
        const totalTokens = data.usage.total_tokens;
        const newTokens = tokens - totalTokens;
        console.log(data.choices[0]);
        const reply = data.choices[0].message.content;
        setLoading(false);
        //Output response to DOM
        setResponse([...responses, { id: responses.length, text: reply }]);
        console.log(responses);
        setTokens(newTokens);
      });
    } else if (tokens == null) {
      setResponse([
        ...responses,
        { id: responses.length, text: "Please try again/ refresh page" },
      ]);
    } else if (tokens <= 0) {
      setResponse([
        ...responses,
        {
          id: responses.length,
          text: "Unfortunately you are out of Tokens :(",
        },
      ]);
    }
  };

  return (
    <motion.div
      className="template-layout"
      variants={transitionVariants}
      initial="hidden"
      animate="visible"
    >
      <Sidebar />

      <div className="template-input">
        <div className="template-description">
          <img
            src={require(`./img/${imgName}.png`)}
            alt="logo"
            className="template-img"
            style={{ float: "left" }}
          />
          <div className="template-info">
            <h5>{heading}</h5>
            <p>{description}</p>
          </div>
        </div>

        <div className="template-input-field">
          <p style={{ float: "right" }}>{nameCount}/80</p>
          <h6>{inputOne}</h6>
          <textarea
            onChange={(e) => handleName(e)}
            maxLength={80}
            className="template-name-textarea txtarea"
            placeholder={placeHolderOne}
          ></textarea>{" "}
          <br />
          <br />
          <p style={{ float: "right", maxLength: "150px" }}>
            {descriptionCount}/800
          </p>
          <h6>{inputTwo}</h6>
          <textarea
            onChange={(e) => handleDescription(e)}
            maxLength={800}
            className="template-description-textarea txtarea"
            style={{ height: `${heightCount}px` }}
            placeholder={placeHolderTwo}
          ></textarea>{" "}
          <br />
          <br />
          <p style={{ float: "right" }}>{toneCount}/80</p>
          <h6>{inputThree}</h6>
          <textarea
            onChange={(e) => handleTone(e)}
            maxLength={80}
            className="template-tone-textarea txtarea"
            placeholder={placeHolderThree}
          ></textarea>{" "}
          <br /> <br /> <br />
          <button onClick={(e) => handleSubmit(e)} className="btn">
            Generate
          </button>
        </div>
      </div>

      <div className="template-output">
        <h5>Response From Samurai AI</h5>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {responses.map((response) => (
              <div key={response.id} className="ai-response">
                {response.text}
              </div>
            ))}
            <FadeLoader
              color={color}
              loading={loading}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </div>
        <button onClick={() => handleClear()} className="btn-clear">
          Clear
        </button>
      </div>
    </motion.div>
  );
}
