import React from "react";
import { NavLink } from "react-router-dom";

const SignedOutLinks = () => {
    return (
       <ul className="right">
        <li><NavLink id="nl" to='/pricing'>Pricing</NavLink></li>
        <li><NavLink id="nl" to='/signin'>Sign In</NavLink></li>
        <li><NavLink id="nl" to='/signup'>Sign Up</NavLink></li>
       </ul>       
    )
}

export default SignedOutLinks
