import React from 'react'
import TemplateStructure from './TemplateStructure'

export default function CompanyBio() {
    const imgName = 'companybio';
    const heading = 'Company Bio';
    const description = 'Generate a company bio that will put you on the radar';
    const inputOne = 'Name of Company'
    const placeHolderOne = 'Samurai'
    const inputTwo = 'Company Description'
    const placeHolderTwo = 'Samurai was founded in 2023 and offers AI services in the marketing field.'
    const inputThree = 'Tone'
    const placeHolderThree = 'Funny, professional, in the tone of Steve Jobs'
    const aiResponse =  'This is an AI generated response'
    const maxTokens = 1000
    const temperature =  1
    

  return (
    <div>
        <TemplateStructure imgName={imgName} heading={heading} description={description} inputOne={inputOne} placeHolderOne={placeHolderOne} inputTwo={inputTwo} placeHolderTwo={placeHolderTwo}
        inputThree={inputThree} placeHolderThree={placeHolderThree} aiResponse={aiResponse} maxTokens={maxTokens} temperature={temperature}/>
    </div>
  )
}