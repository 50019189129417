import React from "react";

const PresentationSummary = () =>{
    return (
        <div className="template">
                <img src={require('../img/presentation.png')} alt="logo" className="template-img" />
                <h5>Presentation</h5>
                <p>Let Samurai convert your document into a presentation format</p>
        </div>
    )
}

export default PresentationSummary