import React from 'react'
import TemplateStructure from './TemplateStructure'

export default function Blog() {
    const imgName = 'blog';
    const heading = 'Blog Creator';
    const description = 'Generate blogs for your website';
    const inputOne = 'Blog Title'
    const placeHolderOne = 'Five Reasons AI Can Make Your Life Easier'
    const inputTwo = 'Blog Content'
    const placeHolderTwo = 'Provide content for your blog or explain to Samurai what blog you would like it to generate for you'
    const inputThree = 'Tone'
    const placeHolderThree = 'Funny, professional, in the tone of Steve Jobs'
    const aiResponse =  'This is an AI generated response'
    const maxTokens = 1000
    const temperature =  1
    

  return (
    <div>
        <TemplateStructure imgName={imgName} heading={heading} description={description} inputOne={inputOne} placeHolderOne={placeHolderOne} inputTwo={inputTwo} placeHolderTwo={placeHolderTwo}
        inputThree={inputThree} placeHolderThree={placeHolderThree} aiResponse={aiResponse} maxTokens={maxTokens} temperature={temperature}/>
    </div>
  )
}