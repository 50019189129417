import React from "react";

const BlogSummary = () =>{
    return (
        <div className="template">
                <img src={require('../img/blog.png')} alt="logo" className="template-img" />
                <h5>Blog Creator</h5>
                <p>Generate blogs for your website</p>
        </div>
    )
}

export default BlogSummary