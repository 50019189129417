import React from 'react'
import TemplateStructure from './TemplateStructure'

export default function Letter() {
    const imgName = 'letter';
    const heading = 'Letter';
    const description = 'Generate professional letters of any type';
    const inputOne = 'Letter Heading'
    const placeHolderOne = 'Proxy letter, Quote request, etc'
    const inputTwo = 'Letter Contents'
    const placeHolderTwo = 'Information such as who the letter is addressed to, what it is about, additional information, etc'
    const inputThree = 'Target'
    const placeHolderThree = 'Businesses, clients, investors, etc'
    const aiResponse =  'This is an AI generated response'
    const maxTokens = 1000
    const temperature =  0.7
    

  return (
    <div>
        <TemplateStructure imgName={imgName} heading={heading} description={description} inputOne={inputOne} placeHolderOne={placeHolderOne} inputTwo={inputTwo} placeHolderTwo={placeHolderTwo}
        inputThree={inputThree} placeHolderThree={placeHolderThree} aiResponse={aiResponse} maxTokens={maxTokens} temperature={temperature}/>
    </div>
  )
}