import React from 'react'
import TemplateStructure from './TemplateStructure'

export default function GoogleAd() {
    const imgName = 'google';
    const heading = 'Google Ad';
    const description = 'Create Attractive Google Ads';
    const inputOne = 'Name of Product/Service'
    const placeHolderOne = '3D Printing Services'
    const inputTwo = 'Information On The Product/Service'
    const placeHolderTwo = 'Specs, ingredients, features, target audience, etc'
    const inputThree = 'Tone'
    const placeHolderThree = 'Witty, inspiring, absurd, etc'
    const aiResponse =  'This is an AI generated response'
    const maxTokens = 1000
    const temperature =  0.7
    

  return (
    <div>
        <TemplateStructure imgName={imgName} heading={heading} description={description} inputOne={inputOne} placeHolderOne={placeHolderOne} inputTwo={inputTwo} placeHolderTwo={placeHolderTwo}
        inputThree={inputThree} placeHolderThree={placeHolderThree} aiResponse={aiResponse} maxTokens={maxTokens} temperature={temperature}/>
    </div>
  )
}