import React, { Component, useState} from "react";
import Sidebar from "../../layout/Sidebar";
import { initializeApp } from 'firebase/app'
import firebaseConfig from '../../../config/fbConfig'
import { doc, onSnapshot, getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { AnimatePresence, motion } from 'framer-motion'
import CancelSubModal from "./CancelSubModal";
import UpgradeModal from "./UpgradeModal";

const transitionVariants = {
    hidden: {
        opacity:0
    },
    visible: {
        opacity: 1,
        transition:{duration:0.5}
    }
}

const hoverVariants = {
    hover:{
        scale: 1.1,
        originX: 0,
        transition:{
            type: 'spring', 
            stiffness: 300
        }
    }
}

ChartJS.register(ArcElement, Tooltip, Legend)

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const auth = getAuth(app)

export default function Dashboard() {

    const [tokens, setTokens] = useState();
    const [maxTokens, setMaxTokens] = useState();
    const [plan, setPlan] = useState ();
    const [billingDate, setBillingDate] = useState();
    const [billingToken, setBillingToken] = useState();
    const [amount, setAmount] = useState();
    const [signature, setSignature] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const [upgradeOpen, setUpgradeOpen] = useState(false);
    const billingLink = `https://www.payfast.co.za/eng/recurring/update/${billingToken}`

    const close = () => setModalOpen(false);
    const open = () => setModalOpen(true);
    const upClose = () => setUpgradeOpen(false);
    const upOpen = () => setUpgradeOpen(true);

    const handleCancelSub = e => {
        e.preventDefault()
        // const options = {
        //     method: 'GET',
        //     url: 'http://localhost:8080/cancelsubscription',
        //     params: {billingToken: billingToken}
        // }

        // axios.request(options).then(res => {
        //     console.log(res.data)
        // })
        // .catch((err) => {
        //     console.log(err);
        // })
    }

     onSnapshot(doc(db, "users", auth.currentUser.uid), (doc) => {
            setTokens(doc.data().tokens)
            setPlan(doc.data().plan)
            setBillingDate(doc.data().billingDate)
            setBillingToken(doc.data().billingToken)
            setAmount(doc.data().amount)
            setSignature(doc.data().signature)
            if (doc.data().plan == 'Basic') {
                setMaxTokens(40000)
            } else if (doc.data().plan == 'Pro') {
                setMaxTokens(100000)
            } else if (doc.data().plan == 'Premium') {
                setMaxTokens(500000)
            } else if (doc.data().plan == 'Free Trial') {
                setMaxTokens(2000)
            }
        });

    const data = {
        labels: ['Used Tokens', 'Available Tokens'],
        datasets: [{
            label: 'Tokens',
            data: [maxTokens-tokens, tokens],
            backgroundColor: ['#d32f2f', '#345d7d'],
            borderColor: ['#455a64' ],
        }]
    }

    const options = {
        plugins: {
            legend: {
                labels: {
                    color: 'white'
                }
            },
        }
    }

    const doughnutText = {
        id: 'doughnutText',
        beforeDatasetsDraw(chart, args, pluginOptions){
           const {ctx, data, chartArea: {top, bottom, left, right, width, height}} = chart; 

           const xCenter = chart.getDatasetMeta(0).data[0].x;
           const yCenter = chart.getDatasetMeta(0).data[0].y;

           ctx.save();
           ctx.fillStyle = 'white';
           ctx.font = '20px sans-serif';
           ctx.textAlign = 'center';
           ctx.fillText(`Available: ${data.datasets[0].data[1]}`, xCenter, yCenter)
        }
    }

  return (
    <div>
        <Sidebar/>
        <motion.div 
            variants={transitionVariants} 
            initial="hidden"
            animate="visible"
            className="dashboard">
            <div className="dashboard-items">
                <div className="dashboard-item token-usage">
                    <h4>Token Usage</h4>
                    <div className="chart">
                        <Doughnut
                        data = {data}
                        options = {options}
                        plugins = {[doughnutText]}
                        ></Doughnut>
                    </div>  
                    <p>You have used {Math.round((maxTokens-tokens)/maxTokens*100)}% of your monthly tokens</p>
                </div>
                {/* <div className="dashboard-item news">
                    <h4>What's New</h4>
                </div>  */}
                <div className="dashboard-item billing">
                    <h4>Billing Details</h4> <br />
                    <div className="billing-block">
                        <p>Current Plan</p> 
                        <h5><b>{plan} Plan</b></h5>
                        <h5>R{amount} p/m</h5>
                        <motion.a style={{float:'right'}} className='btn'
                            variants={hoverVariants}
                            whileHover={'hover'}
                            onClick={() => (upgradeOpen ? upClose() : upOpen())}                        
                        >
                            Upgrade Plan
                        </motion.a>
                    </div>
                    <div className="billing-block">
                        <p>Last Billing Date</p>
                        <h5><b>{billingDate}</b></h5>
                        <motion.a style={{float:'right'}} className='btn' href={billingLink}
                            variants={hoverVariants}
                            whileHover={'hover'}
                        >Update card</motion.a>
                    </div>              
                    <motion.a 
                        variants={hoverVariants}
                        whileHover={'hover'}
                        onClick={() => (modalOpen ? close() : open())}
                        style={{float: 'right'}}
                        >
                            <img src={require('./img/unsubscribe.png')} alt="logo" className="logo-img pointer" />
                    </motion.a>                    
                </div>
                {/* <div className="dashboard-item news">
                    <h4>Coming Soon...</h4>
                </div> */}
            </div>               
        </motion.div>
        <AnimatePresence
                        initial={false}
                        mode="wait"
                        onExitComplete={() => null}
        >
                        {modalOpen && <CancelSubModal modalOpen={modalOpen} handleClose={close} uid={auth.currentUser.uid}/>}
        </AnimatePresence>   
        <AnimatePresence
                        initial={false}
                        mode="wait"
                        onExitComplete={() => null}
        >
                        {upgradeOpen && <UpgradeModal modalOpen={upgradeOpen} handleClose={upClose}/>}
        </AnimatePresence>  
    </div>

  )
}

