import React from 'react'
import TemplateStructure from './TemplateStructure'

export default function Presentation() {
    const imgName = 'presentation';
    const heading = 'Presentation';
    const description = 'Let Samurai convert your document into a presentation format';
    const inputOne = 'Presentation Title'
    const placeHolderOne = 'Get To Market Strategy'
    const inputTwo = 'Information For The Presentation'
    const placeHolderTwo = 'Provide a verbal description on what needs to be presented or provide raw information that can be summarised into the presentation'
    const inputThree = 'Tone'
    const placeHolderThree = 'Funny, professional, in the tone of Steve Jobs'
    const aiResponse = 'This is an AI generated response'
    const maxTokens = 1000
    const temperature =  1

  return (
    <div>
        <TemplateStructure imgName={imgName} heading={heading} description={description} inputOne={inputOne} placeHolderOne={placeHolderOne} inputTwo={inputTwo} placeHolderTwo={placeHolderTwo}
        inputThree={inputThree} placeHolderThree={placeHolderThree} aiResponse={aiResponse} maxTokens={maxTokens} temperature={temperature}/>
    </div>
  )
}
