import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";

const useFetchPayForm = (url, firstName, lastName, email, amount, itemName, cssClass, id) => {
    const [htmlForm, setHtmlForm] = useState('')

    useEffect(() => {
        const payForm = {
            method: 'GET',
            url: url,
            params: {firstName: firstName, lastName: lastName, email: email, amount: amount, itemName: itemName, cssClass: cssClass, id: id }
        }

        axios.request(payForm).then(res => {
            const form = res.data
            setHtmlForm(form)
        })
    },[])

    return htmlForm
}

export default useFetchPayForm