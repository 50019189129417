import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../layout/Footer'
import { motion } from 'framer-motion'

const transitionVariants = {
    hidden: {
        scale:0
    },
    visible: {
        scale: 1,
        transition:{duration:0.5}
    }
}

export default function Pricing() {
  return (
    <div>
        <motion.div
            variants={transitionVariants} 
            initial="hidden"
            animate="visible"
        >
        <h4 className='templates-title'>Pricing</h4>
        <div className='plan-block'>
            <div className='plan'>
                <div>
                    <h4>Basic Plan</h4>
                    <p>For those looking to test Samurai's capabilities</p>
                </div>
                <div className='price basic'>
                    <h3>R350 pm</h3>
                </div>
                <div className='plan-descrip'>
                    <p><b>40 000</b> words/month</p>
                </div>
                <img src={require('../layout/img/basicplan.png')} alt="basic plan" className='plan-img'/> <br />
                {/* <div className='purchase p-basic'><NavLink><h4>Purchase Now</h4></NavLink></div> */}
                <div className='purchase p-basic'><NavLink to='/signup'><h4>Join Now</h4></NavLink></div>
            </div>

            <div className='plan'>
                <div>
                    <h4>Pro Plan</h4>
                    <p>For both individuals and businesses who are frequent users</p>
                </div>
                <div className='price pro'>
                    <h3>R800 pm</h3>
                </div>
                <div className='plan-descrip'>
                    <p><b>100 000</b> words/month</p>
                </div>
                <img src={require('../layout/img/proplan.png')} alt="pro plan" className='plan-img'/> <br />
                <div className='purchase p-pro'><NavLink to='/signup'><h4>Join Now</h4></NavLink></div>
            </div>

            <div className='plan'>
                <div>
                    <h4>Premium Plan</h4>
                    <p>Businesses and individuals using Samurai professionally</p>
                </div>
                <div className='price premium'>
                    <h3>R1 400 pm</h3>
                </div>
                <div className='plan-descrip'>
                    <p><b>500 000</b> words/month</p>
                </div>
                <img src={require('../layout/img/premiumplan.png')} alt="premium plan" className='plan-img'/> <br />
                <div className='purchase p-premium'><NavLink to='/signup'><h4>Join Now</h4></NavLink></div>
            </div>
        </div>
        <h5 className='promo'>Receive 2000 free tokens when you sign up today!</h5>
        </motion.div>
        <div><Footer/></div>
    </div>
  )
}
