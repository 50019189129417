import React from "react";

const GoogleAdSummary = () =>{
    return (
        <div className="template">
                <img src={require('../img/google.png')} alt="logo" className="template-img" />
                <h5>Google Ad</h5>
                <p>Create Attractive Google Ads</p>
        </div>
    )
}

export default GoogleAdSummary