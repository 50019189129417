import React, { useEffect, useState } from 'react'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import firebaseConfig from '../../config/fbConfig'
import { getFirestore } from "firebase/firestore"
import { getDoc, doc } from "firebase/firestore"
import useFetchPayForm from './useFetchPayForm'
import Footer from '../layout/Footer'
import { motion } from 'framer-motion'

const transitionVariants = {
    hidden: {
        scale: 0,
        opacity: 0
    },
    visible: {
        scale: 1,
        opacity: 1,
        transition:{duration:0.5}
    }
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)

export default function Purchase() {
    // UseStates
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const id = auth.currentUser.uid

    //Basic Plan Variables
    const amountBasic = '350.00'
    const itemNameBasic = 'Basic Plan'
    const cssClassBasic = 'p-basic'

    //Pro Plan Variables
    const amountPro = '800.00'
    const itemNamePro = 'Pro Plan'
    const cssClassPro = 'p-pro'

    //Pro Plan Variables
    const amountPremium = '1400.00'
    const itemNamePremium = 'Premium Plan'
    const cssClassPremium = 'p-premium'

    const htmlPro = useFetchPayForm('https://us-central1-samurai-1cb94.cloudfunctions.net/api/paymentauth', firstName, lastName, email, amountPro, itemNamePro, cssClassBasic, id)
    const htmlBasic = useFetchPayForm('https://us-central1-samurai-1cb94.cloudfunctions.net/api/paymentauth', firstName, lastName, email, amountBasic, itemNameBasic, cssClassPro, id)
    const htmlPremium = useFetchPayForm('https://us-central1-samurai-1cb94.cloudfunctions.net/api/paymentauth', firstName, lastName, email, amountPremium, itemNamePremium, cssClassPremium, id)

    useEffect(() => {
        if (auth.currentUser !== null) {
            getDoc(doc(db, "users", id)).then(docSnap => {
            const nameFirst = docSnap.data().name 
            const nameLast = docSnap.data().surname
            const mail = docSnap.data().email
            setFirstName(nameFirst)
            setLastName(nameLast)
            setEmail(mail)
          })} 
    },[auth.currentUser])

  return (
    <div>
        <motion.div
            variants={transitionVariants} 
            initial="hidden"
            animate="visible"
        >
        <h4 className='templates-title'>Pricing</h4>
        <div className='plan-block'>
            <div className='plan scale-transition scale-in'>
                <div>
                    <h4>Basic Plan</h4>
                    <p>For those looking to test Samurai's capabilities</p>
                </div>
                <div className='price basic'>
                    <h3>R350 pm</h3>
                </div>
                <div className='plan-descrip'>
                    <p><b>40 000</b> words/month</p>
                </div>
                <img src={require('../layout/img/basicplan.png')} alt="basic plan" className='plan-img'/> <br />
                <h4><div dangerouslySetInnerHTML={{ __html: htmlBasic }} /></h4>
            </div>

            <div className='plan'>
                <div>
                    <h4>Pro Plan</h4>
                    <p>For both individuals and businesses who are frequent users</p>
                </div>
                <div className='price pro'>
                    <h3>R800 pm</h3>
                </div>
                <div className='plan-descrip'>
                    <p><b>100 000</b> words/month</p>
                </div>
                <img src={require('../layout/img/proplan.png')} alt="pro plan" className='plan-img'/> <br />
                <h4><div dangerouslySetInnerHTML={{ __html: htmlPro }} /></h4>
            </div>

            <div className='plan'>
                <div>
                    <h4>Premium Plan</h4>
                    <p>Businesses and individuals using Samurai professionally</p>
                </div>
                <div className='price premium'>
                    <h3>R1 400 pm</h3>
                </div>
                <div className='plan-descrip'>
                    <p><b>500 000</b> words/month</p>
                </div>
                <img src={require('../layout/img/premiumplan.png')} alt="premium plan" className='plan-img'/> <br />
                <h4><div dangerouslySetInnerHTML={{ __html: htmlPremium }} /></h4>
            </div>
        </div>
        </motion.div>
        <div><Footer/></div>
    </div>
  )
}
